







































































import {Vue,Component,Prop} from 'vue-property-decorator';
import {MayordomoZona,ObtenerAsignacionDetalle} from '@/entidades/Plantacion/MayordomoZona';
import Hacienda from '@/entidades/Plantacion/Hacienda';
import { HaciendaLote } from '@/entidades/Plantacion/HaciendaLote';
import Labor from '@/entidades/Plantacion/Labor';
import {Action, State} from 'vuex-class'
import Alerta from '@/entidades/Sistema/Alerta';
import Contratista from '@/entidades/Plantacion/Contratista';
import PlanificacionForestalResponse, { PlanificacionCabeceraRequest, PlanificacionDetalleRequest } from '@/entidades/Plantacion/PlanificacionForestalResponse';
import {CicloLaborQuery} from '@/entidades/Plantacion/Ciclo';
import Loading from '@/entidades/Sistema/Loading';
import { CuadrillaPlanificacion,ObtenerCuadrilla,ObtenerCuadrillaPlanificacion } from '@/entidades/Plantacion/Cuadrilla';
@Component({
  name : 'FormularioDetalle'
})
export default class FormularioDetalle extends Vue
{
   header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Hacienda",sortable:true,value:'haciendaDescripcion'},
            {text:"Lote",sortable:true,value:'loteDescripcion'},
            {text:"Labor",sortable:true,value:'laborDescripcion'},
            {text:"Orden Interna",sortable:false,value:'ordenInterna'},
            {text:"Proveedor",sortable:false,value:'proveedorDescripcion'},
            {text:"Area Planificada",sortable:false,value:'areaPlanificada'}
  ];
  @State('usuarioId',{namespace: 'authModule'}) usuarioId!:string;
  @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
  @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;

  @Prop({type:Object,required:true}) item!:PlanificacionForestalResponse;
  listadoHacienda:Array<MayordomoZona> = [];
  haciendaId:number=0;
  listadoLote:Array<HaciendaLote> = []
  cuadrillaId:number=0;
  loteId:number=0;
  listadoLabor:Array<Labor> = [];
  laborId:number=0;
  listadoProveedor:Array<Contratista> = [];
  proveedorId:number=0;
  cantidad:number=0;
  listadoPlanificacion:Array<PlanificacionDetalleRequest> =[];
  listadoCuadrilla:Array<CuadrillaPlanificacion> = [];
  // mayordomoZona:MayordomoZona = new MayordomoZona();

  retroceder()
  {
    this.$emit('retrocederDetalle');
  }
  async obtenerDetallePlanificacion(){
    var rsp = await PlanificacionForestalResponse.ObtenerPlanificacionDetalle(this.item.planificacionForestalId);
    console.log(rsp);
    if(rsp.isSuccess)
      this.listadoPlanificacion = rsp.isResult;
  }

  async obtenerLabor()
  {
    var rsp = await Labor.ObtenerSelect();
    if(rsp.isSuccess)
    {
      this.listadoLabor = rsp.isResult;
      this.laborId = this.listadoLabor[0].laborId;
    }
    else
    {
      this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
    }
  }
  async obtenerHacienda()
  {
     var rsp = await ObtenerAsignacionDetalle(this.usuarioId);
     if(rsp.isSuccess)
     {
       this.listadoHacienda = rsp.isResult;
     }
     else
     {
       this.changeAlerta(new Alerta('El usuario no tiene hacienda asignada',true,"danger",3000));
     }
  }
  async obtenerLoteHacienda(haciendaId:number)
  {
    var rsp = await Hacienda.ObtenerLoteIem(haciendaId);
    console.log(rsp);
    if(rsp.isSuccess)
    {
      this.listadoLote = rsp.isResult;
      this.loteId = this.listadoLote[0].haciendaLoteId!;

    }
    else
    {
       this.changeAlerta(new Alerta('No se encontraron lotes para esta hacienda',true,"danger",3000));
    }
  }
  async obtenerProveedor()
  {
    var rsp = await Contratista.ObtenerSelect(1);
    if(rsp.isSuccess)
    {
      this.listadoProveedor =  rsp.isResult;
    }
  }

  async obtenerCuadrilla()
  {
    var rsp = await ObtenerCuadrillaPlanificacion();
    if(rsp.isSuccess)
    {
      this.listadoCuadrilla = rsp.isResult;
      this.cuadrillaId = this.listadoCuadrilla[0].cuadrillaId;
    }
    else
    {
      this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
    }
  }

  async agregar()
  {
    try
    {
      this.changeLoading(new Loading(true,"Validando informacion"));

    //Validaciones
    if(this.haciendaId <=0 || this.loteId <=0 || this.laborId <=0 || this.cantidad <=0)
    {
      this.changeAlerta(new Alerta("Hay campos obligatorios que no esta seleccionando (Hacienda, Lote, Labor, Avance)",true,"danger",4000));
      return;
    }
      var request = new CicloLaborQuery();
      request.haciendaId = this.haciendaId;
      request.loteId = this.loteId;
      request.laborId = this.laborId;
      var rsp = await CicloLaborQuery.ValidarCiclo(request);

      var isResult =  rsp.isResult;
      if(rsp.isSuccess)
      {
        //lo agregamos
        var item = isResult[0]
        if(this.cantidad > item.cantidad)
        {
          this.changeAlerta(new Alerta(`Desea programar Cantidad: ${this.cantidad}, pero actualmente dispone de cantidad: ${item.cantidad}, para que el sistema le permita, agregue un nuevo ciclo`,true,"danger",5000));
          return;
        }
        else
        {
          var labor = this.listadoLabor.find(t=>t.laborId == this.laborId);
          var cuadrilla = this.listadoCuadrilla.find(t=>t.cuadrillaId == this.proveedorId);
          var detalle = new PlanificacionDetalleRequest();
          detalle.cicloId = item.cicloId;
          detalle.areaDisponible = Number(item.cantidad);
          detalle.areaPlanificada = Number(this.cantidad);
          detalle.laborId = this.laborId;
          detalle.laborDescripcion = labor?.descripcion!;
          detalle.tipoLaborId = labor?.tipoLaborId!;
          detalle.haciendaId = this.haciendaId;
          detalle.haciendaDescripcion = this.listadoHacienda.find(t=>t.haciendaIdAsignada == this.haciendaId)?.haciendaName!;
          detalle.loteId = this.loteId;
          detalle.loteDescripcion = this.listadoLote.find(t=>t.haciendaLoteId == this.loteId)?.descripcion!;
          detalle.proveedorId =   cuadrilla?.contratistaId!;
          detalle.proveedorDescripcion = cuadrilla?.contratistaName!;
          detalle.planificacionId = this.item.planificacionForestalId;
          detalle.cuadrillaId = cuadrilla?.cuadrillaId!;
          detalle.cuadrillaName = cuadrilla?.cuadrillaName!;
         
          var loteEnviar = this.listadoLote.find(t=>t.haciendaLoteId == this.loteId);
          if(loteEnviar != undefined)
          {
            if(loteEnviar.ordernInterna)
            {
              detalle.ordenInterna = loteEnviar.ordernInterna;
            }
            else
            {
              detalle.ordenInterna = this.listadoHacienda.find(t=>t.haciendaIdAsignada == this.haciendaId)?.ordenInterna!;
            }

          }


          var responseDetalle = await PlanificacionForestalResponse.GuardarDetalle(detalle);
          if(responseDetalle.isSuccess)
          {
            this.obtenerDetallePlanificacion();
              this.changeAlerta(new Alerta("Labor agregada a la planificacion exitosamente",true,"primary",3000));
          }
          else
          {
            this.changeAlerta(new Alerta(responseDetalle.isMessage,true,"danger",3000));
            return;
          }
        }

      }
      else
      {
        this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
      }
    }
    catch(error)
    {
      console.log(error);
    }
    finally
    {
       this.changeLoading(new Loading(false,""));
    }

  }
  async mounted() {
    await this.obtenerDetallePlanificacion();
    await this.obtenerHacienda();
    await this.obtenerLabor();
    await this.obtenerProveedor();
    await this.obtenerCuadrilla();
  }
}
